import SponsorsLogo from "./SponsorsLogo";

interface IProps {
  center?: boolean;
}

export default function Sponsors({ center = false }: IProps) {
  return (
    <div className={center ? "flexbox flexbox--wrap" : "sponsors"}>
      {/* <SponsorsLogo src="/images/Layer1.png" alt="Fundusze Europejskie" /> */}
      <SponsorsLogo src="/images/slaskie.png" alt="Śląskie" />
      {/* <SponsorsLogo
        src="/images/Layer4.png"
        alt="Europejski fundusz rozwoju regionalnego"
      /> */}
    </div>
  );
}
