import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import HomePage from "./components/HomePage/HomePage";
import BlogPage from "./components/Blog/BlogPage";
import ContactPage from "./components/Contact/ContactPage";
import SchemePage from "./components/Scheme/SchemePage";
import GalleryWithoutCategories from "./components/Gallery/GalleryWithoutCategories";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLastModifiedDateAction,
  fetchNavItemsAction,
  setViewCountAction,
} from "./components/Layout/Layout.slice";
import ScrollToTop from "./components/ScrollTop";
import {
  fetchContactContentAction,
  fetchHomeContentAction,
  fetchSchemaContentAction,
  fetchWorkProgressAction,
} from "./components/HomePage/Home.slice";
import { RootState } from "./components/store";
import Loader from "./components/Layout/Loader";
import useLoader from "./components/Layout/UseLoader.hook";
import WorkProgressPage from "./components/WorkProgress/WorkProgressPage";
import TextPage from "./components/TextPage";
import {
  fetchBlogContentAction,
  fetchPostsAction,
} from "./components/Blog/Blog.slice";
import Page from "./components/Page";

function App() {
  const displayLoader = useSelector(
    (state: RootState) => state.layout.displayLoader
  );

  const loaded = useLoader(
    fetchHomeContentAction,
    fetchPostsAction,
    fetchBlogContentAction,
    fetchSchemaContentAction,
    fetchWorkProgressAction,
    fetchContactContentAction
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNavItemsAction("nav-main"));
    dispatch(setViewCountAction());
    dispatch(fetchLastModifiedDateAction());
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Switch>
        <Route exact path="/aktualnosci">
          <Page title="Aktualności | DW 421 Budowa mostu nad rzeką Odrą">
            <BlogPage />
          </Page>
        </Route>
        <Route exact path="/wizualizacje">
          <Page title="Wizualizacje | DW 421 Budowa mostu nad rzeką Odrą">
            <SchemePage />
          </Page>
        </Route>
        <Route exact path="/postep-prac">
          <Page title="Postęp prac | DW 421 Budowa mostu nad rzeką Odrą">
            <WorkProgressPage />
          </Page>
        </Route>
        <Route exact path="/galeria">
          <Page title="Galeria | DW 421 Budowa mostu nad rzeką Odrą">
            <GalleryWithoutCategories />
          </Page>
        </Route>
        <Route exact path="/dofinansowanie">
          <Page title="Dofinansowanie | DW 421 Budowa mostu nad rzeką Odrą">
            <TextPage slug="dofinansowanie" />
          </Page>
        </Route>
        <Route exact path="/kontakt">
          <Page title="Kontakt | DW 421 Budowa mostu nad rzeką Odrą">
            <ContactPage />
          </Page>
        </Route>
        <Route exact path="/polityka-prywatnosci">
          <Page title="Polityka Prywatności | DW 421 Budowa mostu nad rzeką Odrą">
            <TextPage slug="polityka-prywatnosci" />
          </Page>
        </Route>
        <Route exact path="/">
          <Page title="DW 421 Budowa mostu nad rzeką Odrą">
            <HomePage />
          </Page>
        </Route>
      </Switch>
      <Footer />
      {displayLoader && <Loader />}
    </BrowserRouter>
  );
}

export default App;
