import React from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";

interface IProps {
  title: string;
  children: React.ReactElement;
}

export default function Page({ title, children }: IProps): React.ReactElement {
  useDocumentTitle(title);
  return children;
}
