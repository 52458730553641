import React, { useEffect, useState } from "react";
import { Link, RouteProps, withRouter } from "react-router-dom";
import Navbar from "./Navbar";
import Sponsors from "./Sponsors";

function Header({ location }: RouteProps) {
  const [isNavVisible, toggleNav] = useState(false);

  useEffect(() => {
    toggleNav(false);
  }, [location, toggleNav]);

  return (
    <header className="header">
      <div className="container container--wide flexbox flexbox--sbet flexbox--wrap">
        <Link to="/">
          <img
            src="/images/zdwwk.png"
            alt="Zarząd dróg wojewódzkich w Katowicach"
            className="logo"
          />
        </Link>

        <div className={"sponsors"}>
          <div className="sponsors__img-box flexbox">
            <img
              src={"/images/flaga-godlo_small.png"}
              alt={"Flaga i Godło"}
              className="sponsors__img"
            />
          </div>
        </div>

        <Sponsors />

        <div
          className={`burger flexbox flexbox--col ${
            isNavVisible ? "burger--active" : ""
          }`}
          onClick={() => toggleNav((state) => !state)}
        >
          <div className="burger__bar"></div>
        </div>
      </div>
      <Navbar isNavVisible={isNavVisible} />
    </header>
  );
}

export default withRouter(Header);
