import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { RootState } from "../store";
import { INavItems } from "./Layout.slice";

interface IProps {
  isNavVisible?: boolean;
  isFooter?: boolean;
  navItems: INavItems[];
}

function Navbar({ isNavVisible = false, isFooter = false, navItems }: IProps) {
  return (
    <nav
      className={`navbar ${isNavVisible ? "navbar--active" : ""} ${
        isFooter ? "navbar--footer" : ""
      }`}
    >
      <ul
        className={`container nav flexbox-md flexbox--sbet ${
          isFooter ? "nav--footer" : ""
        }`}
      >
        {navItems?.map((navItem, index) => (
          <li className="nav__item" key={navItem.ID}>
            <NavLink
              className="nav__link"
              to={navItem.url.replace(process.env.REACT_APP_HOST as string, "")}
              activeClassName={index === 0 ? "" : "nav__link--active"}
            >
              {navItem.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

const mapStateToProps = (state: RootState) => ({
  navItems: state.layout.navItems,
});

export default connect(mapStateToProps)(Navbar);
