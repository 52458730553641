import { useSelector } from "react-redux";
import {
  fetchPrivacyPolicyAction,
  fetchFinancingAction,
} from "./HomePage/Home.slice";
import useLoader from "./Layout/UseLoader.hook";
import { RootState } from "./store";
import parse from "html-react-parser";

export default function TextPage({ slug }: { slug: string }) {
  const content = useSelector((state: RootState) => {
    if (slug === "polityka-prywatnosci") {
      return state.home.privacyPolicyContent;
    } else if (slug === "dofinansowanie") {
      return state.home.financingContent;
    }
  });

  let action = null;

  if (slug === "polityka-prywatnosci") {
    action = fetchPrivacyPolicyAction;
  } else if (slug === "dofinansowanie") {
    action = fetchFinancingAction;
  }

  useLoader(action);

  return (
    <div className="section container container--narrow">
      {content && parse(content)}
    </div>
  );
}
